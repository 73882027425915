<template>
    <section id="profil">
        <h1 class="text-center">Espace utilisateurs</h1>
        <p class="text-center">Vous êtes praticien et vous n'avez pas encore de compte, cliquez sur le bouton ci-dessous</p>

        <div class="container">
            <div class="d-flex justify-content-around">
                <button class="btn btn-primary" @click="login=true"> Se connecter </button>
                <button class="btn btn-primary" @click="login=false"> S'inscrire </button>
            </div>
        </div>

        <div class="container">
            <!-- login -->
            <div class="card mt-4" v-show="login">
                <div class="card-body">
                    <h3>Connexion</h3>
                     <b-form-group
                     label="Nom d'utilisateur"
                     >
                        <b-form-input placeholder="Nom d'utilisateur"></b-form-input>
                    </b-form-group>
                     <b-form-group label="Mot de passe">
                        <b-form-input type="password" placeholder="Mot de passe"></b-form-input>
                    </b-form-group>
                    <hr>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary"> Se connecter</button>
                    </div>
                </div>
            </div>
            <!-- sign in -->
            <div class="card mt-4" v-show="!login">
                <div class="card-body">
                    <h3>Inscription</h3>
                     <b-form-group label="Adresse email">
                        <b-form-input placeholder="Adresse email" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                     <b-form-group label="Nom d'utilisateur">
                        <b-form-input type="text" placeholder="Nom d'utilisateur" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                     <b-form-group label="Mot de passe">
                        <b-form-input type="password" placeholder="Mot de passe" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                     <b-form-group label="Confirmation du Mot de passe">
                        <b-form-input type="password" placeholder="Confirmation du Mot de passe" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                    <br>
                    <b-form-group label="Titre">
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="selected"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <b-form-group label="Nom">
                        <b-form-input type="text" placeholder="Nom" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Prénoms">
                        <b-form-input type="text" placeholder="Prénoms" class="col-md-6 col-sm-12"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Adresse">
                        <b-form-input type="text" placeholder="Adresse"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Ville">
                        <b-form-input type="text" placeholder="Ville" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Profession">
                        <b-form-input type="text" placeholder="Profession" class="col-md-5 col-sm-12"></b-form-input>
                    </b-form-group>
                    <hr>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary"> S'inscrire</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"profil",
    data(){
        return{
            options:['Monsieur', 'Madame', 'Autre'],
            login:null
        }
    },
    mounted(){
    }
}
</script>
<style scoped>

</style>